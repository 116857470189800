<template>
  <div>
    <video ref="videoPlayer" class="video-js vjs-big-play-centered"></video>
  </div>
</template>
<script>
import "video.js/dist/video-js.css";
import videojs from "video.js";
export default {
  name: "VideoPlayerPeTeacher",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    "options.poster": function () {
      console.log("video updated");
      if(this.player.getPlayers){
          delete this.player.getPlayers
      }
      this.player.src(
          this.options.sources[0]
      )
      this.player.poster(this.options.poster)
      this.player.controlBar.progressControl.disable();
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {
        // console.log("onPlayerReady", this);
      }
    );
    this.player.controlBar.progressControl.disable();
  },
  beforeDestroy() {
    this.player.dispose();
  },
};
</script>

